/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

body {
    box-sizing: border-box;
}

:focus {
    outline: 1px dashed #000!important;
}

button:focus {
    outline: 2px solid #003a70!important;
}

a {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: 0 0;
    text-decoration: none;
    color: #005499;
}

